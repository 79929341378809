<template>
    <v-container>
        Auth
    </v-container>
  
</template>

<script>
    import axios from '../../../service/axios'

    export default {
        name: 'Page',
        components: {
        },

        data () {
            return {
            }
        }, // data

        computed: {
        // ...mapState('G_NAME', [])
        }, // computed

        async mounted () {


            const kakaoHeader = {
                'Authorization': '458f0627ada84d9f52fa702e3dfc7e69',  // admin key
                'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS",
                "Access-Control-Allow-Credentials": true
            };

            const getKakaoToken = async (code) => {
                console.log('loginWithKakao');
                try {
                    const data = {
                        grant_type: 'authorization_code',
                        client_id: '4a3461a486bda6bc314e726e5d6ec59e',  //rest key
                        redirect_uri: 'http://localhost:8080/sign/login',
                        code: code,
                    };
                    // rest api : a01c66e77df115b558609d046f34cb94 
                    // javascirtp : 4a3461a486bda6bc314e726e5d6ec59e
                    const queryString = Object.keys(data)
                        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
                        .join('&');

                    console.log('queryString :: ', queryString)
                    const result = await axios.post(`https://kauth.kakao.com/oauth/token`, queryString, { headers: kakaoHeader });

                    console.log('카카오 토큰', queryString);
                    console.log(result)
                    return result;
                } catch (e) {
                    return e;
                }
            };

            const refreshToken = async () => {
                try {
                    const { result } = (await axios.get('/refreshToken')).data;
                    this.$cookies.ies.set('token', result.access_token);
                    console.log('Refresh API 성공', result);
                    return result;
                } catch (e) {
                    console.log(e);
                }
            }

            getKakaoToken()
            refreshToken()


        }, // mounted

        methods: {
        }, // methods

    } // export 
  
</script>

<style scoped>

</style>
